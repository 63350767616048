import { Navbar } from "@gs/fe-storybook-gasco";

export default function Root(props) {
  return (
    <Navbar
      user={{
        name: "Maria Echeberria",
        role: "Administrador",
        points: 1000,
      }}
    />
  );
}
